<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-12">
          <!-- 사업장 -->
          <c-plant type="search" :changeItem="changeItem" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-12">
          <c-text
            :afterIcon="
              [
                {
                  name: 'search',
                  click: true,
                  callbackName: 'searchWorkPermit',
                },
                {
                  name: 'close',
                  click: true,
                  callbackName: 'removeWorkPermit',
                },
              ]
            "
            :editable="editable"
            :readonly="true"
            :changeItem="changeItem"
            label="작업허가번호"
            name="permitNo"
            v-model="searchParam.permitNo"
            @searchWorkPermit="searchWorkPermit"
            @removeWorkPermit="removeWorkPermit"
          >
          </c-text>
        </div>
        <!-- <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-select
            :comboItems="statusItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="tbmCompleteFlag"
            label="작성상태"
            v-model="searchParam.tbmCompleteFlag"
          ></c-select>
        </div> -->
        <!-- <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-process
            :editable="editable"
            :plantCd="searchParam.plantCd"
            label="공종"
            name="processCd"
            v-model="searchParam.processCd"
          >
          </c-process>
        </div> -->
        <div class="col-6">
          <c-field
            :editable="editable"
            label="TBM리더"
            name="tbmLeaderId"
            v-model="searchParam.tbmLeaderId"
            :changeItem="changeItem"
          />
        </div>
        <div class="col-6">
          <c-vendor
            label="업체"
            name="vendorCd"
            v-model="searchParam.vendorCd"
            :editable="editable"
            :changeItem="changeItem"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-datepicker
            :range="true"
            label="작업기간"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="period"
          />
        </div>
        
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <!-- <div class="card-header q-table-header">
        TBM
      </div> -->
      <div class="card-body qtable-card-body">
        <q-table
          grid
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(props.row)">
                <q-card-section class="grid-card-etc">
                  <span style="font-weight: bolder;" class="text-gray-box txt-box-grid">
                    {{ props.row.tbmNo }} 
                  </span>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.tbmName }}</div>
                </q-card-section>
                <q-card-section>
                  <span>
                    {{ props.row.tbmDate }} 
                  </span>
                  <span style="margin:0 2px;"></span>
                  <span>
                    {{ props.row.workTime }} 
                  </span>
                  <span style="margin:0 2px;"></span>
                  <span>
                    {{ props.row.tbmLocation }} 
                  </span>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="bg-grey-2 text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mobileConfig from '@/js/mobileConfig';
import mixinFooterSearch from '@/js/mixin-footer-search-common'

export default {
  name: 'tbm',
  mixins: [mixinFooterSearch],
  props: {
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmNo',
            field: 'tbmNo',
            label: 'TBM번호',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmName',
            field: 'tbmName',
            label: 'TBM명',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'permitNo',
            field: 'permitNo',
            label: '작업허가번호',
            style: 'width:120px',
            align: 'center',
            type: 'link',
            sortable: true,
          },
          {
            name: 'tbmLocation',
            field: 'tbmLocation',
            label: '장소',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmDate',
            field: 'tbmDate',
            label: 'TBM일',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workTime',
            field: 'workTime',
            label: 'TBM시간',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          // {
          //   name: 'tbmCompleteFlagName',
          //   field: 'tbmCompleteFlagName',
          //   label: '작성상태',
          //   style: 'width:80px',
          //   align: 'center',
          //   sortable: true,
          // },
          // {
          //   name: 'processName',
          //   field: 'processName',
          //   style: 'width:120px',
          //   label: '공종',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            name: 'vendorName',
            field: 'vendorName',
            style: 'width:80px',
            label: '업체',
            align: 'center',
            sortable: true,
          },
          // {
          //   name: 'sopName',
          //   field: 'sopName',
          //   style: 'width:120px',
          //   label: '작업',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            name: 'tbmLeaderName',
            field: 'tbmLeaderName',
            label: 'TBM 리더',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      period: ['', ''],
      searchParam: {
        startYmd: '',
        endYmd: '',
        plantCd: '',
        processCd: '',
        vendorCd: '',
        permitNo: '',
        sopWorkPermitId: '',
        tbmCompleteFlag: null,
        tbmLeaderId: ''
      },
      statusItems: [
        { code: null, codeName: '전체' },
        { code: 'N', codeName: '작성중' },
        { code: 'Y', codeName: '작성완료' },
      ],
      changeItem: {
        plant: '',
        vendor: '',
        permitNo: '',
        tbmCompleteFlag: null,
        tbmLeaderId: ''
      },
      editable: true,
      listUrl: '',
      getUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  beforeRouteLeave(to, from, next) { // history.back 일경우 팝업이라면 팝업을 닫자
    if (this.popupOptions.visible) {
      this.closePopup();
      next(false);
    } else {
      next();
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sai.tbm.list.url;
      this.getUrl = mobileConfig.sai.tbm.get.mobile.url;

      if (this.$route.query.tbmId) {
        this.$http.url = this.$format(this.getUrl, this.$route.query.tbmId, this.$store.getters.user.userId)
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if(_result.data){
            this.goSigPopup();
          }
        })
      } 
      this.getList();
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'N': // 작성중
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'Y': // 완료
          cls = 'txt-box-grid text-positive-box';
          break;
      }
      return cls;
    },
    getList() {
      this.searchParam.startYmd = this.period ? this.period[0] : ''
      this.searchParam.endYmd = this.period ? this.period[1] : ''
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    goSigPopup() {
      this.popupOptions.title = 'TBM 참석자 서명하기'; 
      this.popupOptions.param = {
        tbmId: this.$route.query.tbmId,
      };
      this.popupOptions.target = () => import(`${'./signaturePop.vue'}`);
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    linkClick(row) {
      this.popupOptions.target = () => import(`${"./tbmDetail.vue"}`);
      this.popupOptions.title = 'TBM 상세';
      this.popupOptions.param = {tbmId: row ? row.tbmId : '',};
      this.popupOptions.isFull = true;
      // this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    searchWorkPermit() {
      this.popupOptions.title = "작업허가서 선택"
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () => import(`${"@/pages/sop/swp/safeWorkPermitPop.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.searchParam.sopWorkPermitId = data[0].sopWorkPermitId;
        this.searchParam.permitNo = data[0].permitNo;
      }
    },
    removeWorkPermit() {
      this.searchParam.sopWorkPermitId = '';
      this.searchParam.permitNo = '';
    },
  }
};
</script>
